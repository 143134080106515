import React, { useState, Suspense, } from 'react';
import { BrowserRouter, } from 'react-router-dom';
import AppRouter from './router/AppRouter';
import { AdapterDateFns } from '@mui/x-date-pickers/AdapterDateFns';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import AppContext from './context/AppContext';
import LinearProgress from '@mui/material/LinearProgress';

const App = () => {
  const [user, setUser] = useState({
    isLoggedIn: false,
    modules: [],
  });

  return (
    <LocalizationProvider dateAdapter={AdapterDateFns}>
      <AppContext.Provider
        value={{
          user: [user, setUser],
        }}
      >
        <Suspense fallback={<LinearProgress />}>
          <BrowserRouter>
            <AppRouter />
          </BrowserRouter>
        </Suspense>
      </AppContext.Provider>
    </LocalizationProvider>
  );
}

export default App;
