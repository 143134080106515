import React, { useState, } from 'react';
import AppContainerContext from '../context/AppContainerContext';
import { Outlet, } from 'react-router-dom';

const AppContainer = (props) => {
  const { children, } = props;

  const [visualList, setVisualList] = useState([]);
  const [visualPadList, setVisualPadList] = useState([]);
  const [archivedVisualList, setArchivedVisualList] = useState([]);
  const [isVisualListFetched, setIsVisualListFetched] = useState(false);
  const [isVisualPadListFetched, setIsVisualPadListFetched] = useState(false);
  const [isArchivedVisualListFetched, setIsArchivedVisualListFetched] = useState(false);
  const [visualListScrollYPos, setVisualListScrollYPos] = useState(0);
  const [visualPadScrollYPos, setVisualPadScrollYPos] = useState(0);
  const [archivedVisualScrollYPos, setArchivedVisualScrollYPos] = useState(0);

  return (
    <AppContainerContext.Provider
      value={{
        visuals: [visualList, setVisualList, isVisualListFetched, setIsVisualListFetched, visualListScrollYPos, setVisualListScrollYPos],
        visualPads: [visualPadList, setVisualPadList, isVisualPadListFetched, setIsVisualPadListFetched, visualPadScrollYPos, setVisualPadScrollYPos],
        archivedVisuals: [archivedVisualList, setArchivedVisualList, isArchivedVisualListFetched, setIsArchivedVisualListFetched, archivedVisualScrollYPos, setArchivedVisualScrollYPos],
      }}
    >
      {children}
      <Outlet />
    </AppContainerContext.Provider>
  );
}

export default AppContainer;