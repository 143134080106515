import React, { useState, useMemo, useContext, useEffect, useCallback, lazy, useRef, } from 'react';
import { useRoutes, useLocation, useNavigate, } from 'react-router-dom';
import AppContext from '../context/AppContext';
import AppContainer from '../components/AppContainer';
import jsAPI from '../helpers/jsAPI';
import privateRoutes from './privateRoutes';
import publicRoutes from './publicRoutes';
import LinearProgress from '@mui/material/LinearProgress';

const PageNotFound = lazy(() => import('../views/PageNotFound'));

const getRoutes = (routes) => {
  const arr = [];

  for (const route of routes) {
    const { path, ...others } = route;
    if (Array.isArray(path)) {
      for (let p of path) {
        arr.push({ ...others, path: p, });
      }
    }
    else {
      arr.push({  ...others, path, });
    }
  }

  return arr;
}

const AppRoutes = (props) => {
  const { isLoggedIn, modules, } = props;

  const arrRoutes = useMemo(() => {
    let arr = [...getRoutes(publicRoutes)];

    if (isLoggedIn === true) {
      let _privateRoutes = [];

      // add routes that user has access to
      for (const route of privateRoutes) {
        const module = modules.find(el => el.Code === route.accessKey);
        if (module || route.accessKey === "always_show") {
          _privateRoutes = [..._privateRoutes, {...route}];
        }
      }

      const newPrivateRoutes = [{
        element: <AppContainer />,
        children: [...getRoutes(_privateRoutes)]
      }];

      arr = [...arr, ...newPrivateRoutes];
    }

    // add page not found
    arr = [...arr, {
      element: <PageNotFound />,
      path: "*",
    }];

    return arr;
  }, [isLoggedIn, modules]);

  return (
    useRoutes(arrRoutes)
  );
}

const AppRouter = () => {
  const routerLocation = useLocation();
  const routerHistory = useNavigate();
  const userContext = useContext(AppContext);
  const [user, setUser] = userContext.user;
  const [loading, setLoading] = useState(true);

  const firstRender = useRef(true);

  const getUserDetails = useCallback(async () => {
    try {
      const responseJSON = await jsAPI.request("/api/users/get_details");

      setUser(user => {
        return {
          ...user,
          modules: [...responseJSON.modules],
          ...responseJSON.others,
        }
      });
    }
    catch {}
  }, [setUser]);

  const checkUserSession = useCallback(async () => {
    try {
      const pathName = routerLocation.pathname.toLowerCase();
      setLoading(true);
      const responseJSON = await jsAPI.request("/api/login/check_session");
      setUser(user => {
        return {
          ...user,
          isLoggedIn: responseJSON.success,
        }
      });

      if (responseJSON.success === true) {
        if (pathName === "/login" || pathName === "/") {
          routerHistory("/app");
        }

        await getUserDetails();
      }
      else {
        if (pathName === "/" || pathName.split("/")[1] === "app") {
          routerHistory("/login");
        }
      }
    }
    catch {}
    finally {
      setLoading(false);
    }
  }, [setUser, getUserDetails, routerLocation.pathname, routerHistory]);

  useEffect(() => {
    if (firstRender.current === true) {
      checkUserSession();
    }
  }, [checkUserSession]);

  // this effect should always be the last effect
  useEffect(() => {
    firstRender.current = false;
  }, []);

  return (
    <>
      {loading === true ? (
        <LinearProgress />
      ) : (
        <AppRoutes
          isLoggedIn={user.isLoggedIn}
          modules={user.modules}
        />
      )}
    </>
  );
}

export default AppRouter;