import React, { lazy, } from 'react';
const Home = lazy(() => import('../views/Home'));
const VisualPad = lazy(() => import('../forms/VisualPad'));
const Visualization = lazy(() => import('../forms/Visualization'));
const Users = lazy(() => import('../views/Users'));

const privateRoutes = [
  { element: <Home />, path: "app", accessKey: "always_show", },
  { element: <VisualPad />, path: ["visual_pad", "app/visual_pad/new", "app/visual_pad/:id", "app/visual_pad/edit/:id"], accessKey: "analytics.visualpad", },
  { element: <Visualization />, path: ["app/visualizations/:id", "app/visualizations/preview/:id"], accessKey: "analytics.visualizations", },
  { element: <VisualPad />, path: ["archived_visual"], accessKey: "analytics.visualpad", },
  { element: <Users />, path: "app/users", accessKey: "users.view_users", },
]

export default privateRoutes;